import React, { useState, useEffect } from 'react';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  
  // 假設您有一個WebSocket連接
  useEffect(() => {
    // const socket = new WebSocket('ws://example.com/chat');

    // // 監聽新消息
    // socket.addEventListener('message', (event) => {
    //   const receivedMessage = JSON.parse(event.data);
    //   setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    // });

    // // 關閉連接時的處理
    // return () => {
    //   socket.close();
    // };
  }, []);

  const sendMessage = () => {
    // 假設您將新消息傳送到WebSocket服務器
    const messageToSend = { text: newMessage, timestamp: Date.now() };
    // 傳送消息
    // socket.send(JSON.stringify(messageToSend));

    // 清除輸入欄
    setNewMessage('');
  };

  return (
    <div>
      <div>
        {/* 公屏 */}
        <div>
          {messages.map((message) => (
            <div key={message.timestamp}>
              <span>{message.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        {/* 私人訊息的聯絡人列表 */}
        <ul>
          <li>User 1</li>
          <li>User 2</li>
          <li>User 3</li>
          {/* 添加更多聯絡人 */}
        </ul>
      </div>
      <div>
        {/* 輸入新消息 */}
        <input
          type="text"
          placeholder="輸入消息"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={sendMessage}>發送</button>
      </div>
    </div>
  );
}

export default Chat;
