import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

function TodoList() {
  const [todos, setTodos] = useState([]);
  const [todoText, setTodoText] = useState('');

  const addTodo = () => {
    if (todoText.trim() === '') return;
    setTodos([...todos, todoText]);
    setTodoText('');
  };

  const deleteTodo = (index) => {
    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);
  };

  return (
    <div className="flex flex-col">
      <h2 className="text-2xl mb-4">在這裡輸入Prompt</h2>
      <TextField
        label="Add a new todo"
        variant="outlined"
        fullWidth
        value={todoText}
        onChange={(e) => setTodoText(e.target.value)}
        className="mb-2"
      />
      <Button variant="contained" color="primary" onClick={addTodo} className="mb-4">
        Add
      </Button>
      <List>
        {todos.map((todo, index) => (
          <ListItem key={index} className="border mb-2 p-2 rounded">
            <ListItemText primary={todo} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => deleteTodo(index)} edge="end" aria-label="delete">
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default TodoList;
