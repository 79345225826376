import React from 'react';
import { Paper, LinearProgress, Typography, Box, IconButton } from '@mui/material';
import { StarOutline, MonetizationOn, LocalActivity } from '@mui/icons-material'; // Replace with your preferred icons

function Header() {
  // 假設您有一些相關的數據，例如目前等級、點數、經驗值和下一等級所需的經驗值
  const currentLevel = 5;
  const points = 350;
  const experience = 70;
  const nextLevelExperience = 1000; // 下一等級所需的經驗值

  // 計算兩個經驗條的百分比
  const experiencePercentage = (experience / nextLevelExperience) * 100;

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" alignItems="center">
        <IconButton color="primary" size="small">
          {/* Replace with your preferred icon for current level */}
          <StarOutline fontSize="small" />
        </IconButton>
        <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
          {currentLevel}
        </Typography>

        <IconButton color="primary" size="small">
          {/* Replace with your preferred icon for points */}
          <MonetizationOn fontSize="small" />
        </IconButton>
        <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
          {points}
        </Typography>

        <IconButton color="primary" size="small">
          {/* Replace with your preferred icon for points */}
          <LocalActivity fontSize="small" />
        </IconButton>
        <div className='flex items-center w-full block mr-2 text-center'>
          <LinearProgress variant="determinate" value={experiencePercentage} sx={{ flex: 3, height: `0.9rem` }} />
          <Typography variant="overline" display="block"
          sx= {{position: `absolute`,
            right: `4rem`,
            left: `12.5rem`, }}
    >{experience} / {nextLevelExperience}</Typography>
        </div>
        {/* <LinearProgress variant="determinate" value={experiencePercentage} sx={{ flex: 3 }} />

        <Typography variant="body2" color="textSecondary" className='fixed'>
          {experience} / {nextLevelExperience}
        </Typography> */}
      </Box>
    </Paper>
  );
}
export default Header;
