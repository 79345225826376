import React, { useState } from 'react';

function Backpack() {
  const [items, setItems] = useState([
    { id: 1, name: 'Item 1', size: 1, thumbnail: 'logo512.png', prompt: 'wolf, black hair' },
    { id: 2, name: 'Item 2', size: 1, thumbnail: 'logo512.png' },
    { id: 3, name: 'Item 3', size: 1, thumbnail: 'logo512.png' },
    { id: 4, name: 'Item 4', size: 1, thumbnail: 'logo512.png' },
    { id: 5, name: 'Item 5', size: 1, thumbnail: 'logo512.png' },
    { id: 6, name: 'Item 6', size: 1, thumbnail: 'logo512.png' },
    { id: 7, name: 'Item 7', size: 1, thumbnail: 'logo512.png' },
    { id: 8, name: 'Item 8', size: 1, thumbnail: 'logo512.png' },
    { id: 9, name: 'Item 9', size: 1, thumbnail: 'logo512.png' },
    { id: 10, name: 'Item 10', size: 1, thumbnail: 'logo512.png' },
    { id: 11, name: 'Item 11', size: 1, thumbnail: 'logo512.png' },
    { id: 12, name: 'Item 12', size: 1, thumbnail: 'logo512.png' },
    // Add more items
  ]);

  const [selectedItem, setSelectedItem] = useState(null);

  const backpackSize = 10; // Total backpack size

  const usedSpace = items.reduce((total, item) => total + item.size, 0);

  const handleDeleteItem = (itemId) => {
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
  };

  return (
    <div className='flex flex-col'>
      <h1>Backpack 背包空間: {usedSpace}/{backpackSize}</h1>
      <div className="grid grid-cols-3 gap-4">
        {items.map((item) => (
          <div key={item.id} className="border p-2 cursor-pointer" onClick={() => setSelectedItem(item)}>
            <img src={item.thumbnail} alt={item.name} className="max-h-32" />
            <p>{item.name}</p>
            <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
          </div>
        ))}
      </div>

      {selectedItem && (
        <div className="mt-4">
          <h2>Item Details</h2>
          <p>Name: {selectedItem.name}</p>
          <p>Size: {selectedItem.size}</p>
          <button onClick={() => setSelectedItem(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default Backpack;
