import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import {
  HomeOutlined,
  FlareOutlined,
  BackpackOutlined,
  ChatOutlined,
  AssignmentIndOutlined,
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";

export default function Footer() {
  const location = useLocation();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const buttons = [
    { label: 'footer.home', to: '/', icon: <HomeOutlined /> },
    { label: 'footer.creator', to: '/creator', icon: <FlareOutlined /> },
    { label: 'footer.backpack', to: '/backpack', icon: <BackpackOutlined /> },
    // { label: 'footer.chat', to: '/chat', icon: <ChatOutlined /> },
    { label: 'footer.profile', to: '/profile', icon: <AssignmentIndOutlined /> },
  ];

  return (
    <Paper sx={{ position: 'relative', bottom: 0, left: 0, right: 0 }} elevation={3}>

    <BottomNavigation
      // showLabels
      value={value}
      onChange={handleChange}
      className="bottom-0 left-0 right-0 bg-gray-200"
    >
      {buttons.map((button, index) => (
          <BottomNavigationAction
            key={index}
            component={Link}
            to={button.to}
            label={t(button.label)} // 這裡可能需要使用 t() 函數進行本地化翻譯
            value={button.to}
            icon={button.icon}
            sx={{minWidth: '0'}}
          />
        ))}
    </BottomNavigation>
    </Paper>
  );
}

