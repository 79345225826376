import React, { useState } from "react";
import {
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Chip,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CheckCircleOutline } from "@mui/icons-material"; // 引入完成圖示

function Creator() {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false); // 新增一個狀態來控制 Backdrop 的顯示
  const [selectedSettings, setSelectedSettings] = useState([]); // 存儲當前選擇的設定
  const [availableCategories, setAvailableCategories] = useState([
    // 初始的種類設定，您可以自行擴充
    {
      id: "category1",
      name: "種類 1",
      options: ["選項 1", "選項 2", "選項 3", "選項 4", "選項 5", "選項 6"],
    },
    {
      id: "category2",
      name: "種類 2",
      options: ["選擇 7", "選項 8", "選項 9", "選項 10", "選項 11", "選項 12"],
    },
    {
      id: "category3",
      name: "種類 3",
      options: [
        "選項 13",
        "選項 14",
        "選項 15",
        "選項 16",
        "選項 17",
        "選項 18",
      ],
    },
    // 添加更多種類
  ]);
  const [processing, setProcessing] = useState(false); // 初始化處理中狀態為 false

  const handleComplete = () => {
    // 點擊完成按鈕後，將處理中狀態設置為 true
    setProcessing(true);

    // 執行您的處理邏輯，例如發送請求或執行其他操作

    // 在完成後，您可以將處理中狀態設置為 false
    // 例如：setTimeout(() => setProcessing(false), 2000); // 2 秒後重置處理中狀態
    // 同時，打開 Backdrop 來使頁面變暗
    setIsBackdropOpen(true);
  };

  // 處理選擇設定
  const handleSelectSetting = (setting) => {
    if (!processing) {
      // 如果沒有在處理中，則可以選擇設定
      if (selectedSettings.includes(setting)) {
        // 如果設定已經被選擇，則取消選擇
        setSelectedSettings(
          selectedSettings.filter((item) => item !== setting)
        );
      } else {
        // 否則，添加到選擇的設定中
        setSelectedSettings([...selectedSettings, setting]);
      }
    }
  };

  // 處理取消選擇設定
  const handleDeselectSetting = (setting) => {
    if (!processing) {
      setSelectedSettings(selectedSettings.filter((item) => item !== setting));
    }
  };

  // 設定每行顯示的選項數
  const itemsPerRow = 4;

  // 將選擇的設定按種類分類
  const categorizedSettings = availableCategories.map((category) => ({
    category: category.name,
    settings: selectedSettings.filter((setting) =>
      category.options.includes(setting)
    ),
  }));

  // 將當前選擇的設定關鍵詞用逗號分隔
  const selectedKeywords = categorizedSettings
    .map((categorySettings) => {
      return categorySettings.settings.map((setting) => setting);
    })
    .flat()
    .join(", ");

  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="h-full relative flex flex-col">
      <div className="flex-grow">
        <p>選擇想要創造的設定:</p>
        {availableCategories.map((category) => (
          <Accordion key={category.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{category.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {category.options.map((option) => (
                  <Grid item xs={6} sm={3} key={option}>
                    <Button
                      variant={
                        selectedSettings.includes(option)
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      onClick={() => {
                        if (selectedSettings.includes(option)) {
                          handleDeselectSetting(option);
                        } else {
                          handleSelectSetting(option);
                        }
                      }}
                      fullWidth
                    >
                      {option}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div className="sticky bottom-0 left-0 right-0">
        <Accordion expanded={expanded} onChange={toggleAccordion}>
          <AccordionSummary
            expandIcon={expanded ? <ExpandLessIcon /> : <NavigateNextIcon />}
          >
            <Typography>
              目前選擇: {expanded ? null : selectedKeywords}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-2">
              <div className="flex flex-wrap">
                {categorizedSettings.map((categorySettings) => (
                  <div key={categorySettings.category} className="mr-4 mb-4">
                    <h3 className="text-lg font-semibold">
                      {categorySettings.category}
                    </h3>
                    <div className="space-x-2">
                      {categorySettings.settings.map((setting) => (
                        <Chip
                          label={setting}
                          color="primary"
                          onDelete={() => handleDeselectSetting(setting)}
                          key={setting}
                          className="mb-2"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-end items-center">
                {processing ? (
                  <CircularProgress />
                ) : selectedSettings.length === 0 ? ( // 檢查 selectedSettings 是否為空
                  <IconButton color="success" disabled>
                    <CheckCircleOutline fontSize="large" />
                  </IconButton>
                ) : (
                  <IconButton color="success" onClick={handleComplete}>
                    <CheckCircleOutline fontSize="large" />
                  </IconButton>
                )}
              </div>
            </div>
          </AccordionDetails>
          <Backdrop open={isBackdropOpen} style={{ zIndex: 999 }}>
            <Typography
              variant="h6"
              color="inherit"
              className="flex flex-col justify-between items-center text-center"
            >
              合成中...<br />
              不需等待可切換到其他頁面
              <CircularProgress className="mt-3" /> {/* 放在下一行並置中對齊 */}
            </Typography>
          </Backdrop>
        </Accordion>
      </div>
    </div>
  );
}

export default Creator;
