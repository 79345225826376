import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TodoList from './TodoList';
import Footer from './Footer';
import Header from './Header';
import Backpack from './Backpack';
import Creator from './Creator';
import Chat from './Chat';
import Profile from './Profile';

function App() {
  return (
    <BrowserRouter>
      <div className='h-screen relative flex flex-col'>
        <Header />
        <div className='flex-grow overflow-y-auto scrollbar-hide px-4'>
          <Routes>
            <Route path="/backpack" element={<Backpack />} />
            <Route path="/creator" element={<Creator />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/" element={<TodoList />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;