import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import './i18n';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// 創建黑暗模式主題
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // primary: {
    //   main: '#A71D31',
    // },
    // secondary: {
    //   main: '#3F0D12',
    // },
    // background: {
    //   default: '#D5BF86',
    //   paper: '#F1F0CC',
    // },
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline /> {/* 這個元件用於重置一些默認樣式 */}
      <div className="App">
      <App />
      </div>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
