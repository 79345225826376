import React from 'react';
import { Paper, Typography, Grid, Button, Divider } from '@mui/material';
import { Share, Edit, Star, PersonOutline, DateRange, LocationOn, AccountCircle, ConfirmationNumber, AssignmentInd, LocalAtm, Gavel } from '@mui/icons-material';
import { useTranslation } from "react-i18next";


function VisaInfo() {
  const { t } = useTranslation();
  const visaData = {
    visaType: '𝕍𝕀𝕊𝕀𝕋𝕆ℝ',
    placeOfIssue: 'New York, USA',
    applicantName: 'John Doe',
    gender: 'Male',
    nationality: '美國',
    dateOfBirth: '1990-01-15',
    passportNumber: 'ABC123456',
    visaNumber: 'V12345678',
    issuingAuthority: 'Furry Institute',
    conditions: 'Visitor',
    entries: 'Single',
    dateOfApplication: '2023-08-20',
    visaFee: '$100',
  };

  const shareVisaInfo = () => {
    // 在此處實現分享功能，例如分享到社交媒體或生成分享鏈接
    // ...
  };

  const visaFields = [
    { icon: <PersonOutline />, label: t('profile.surname'), value: visaData.applicantName },
    { icon: <DateRange />, label: t('profile.dateOfBirth'), value: visaData.dateOfBirth },
  // { icon: <LocationOn />, label: t('profile.placeOfIssue'), value: visaData.placeOfIssue },
  { icon: <AccountCircle />, label: t('profile.gender'), value: visaData.gender },
  { icon: <ConfirmationNumber />, label: t('profile.passportNumber'), value: visaData.passportNumber },
  { icon: <AssignmentInd />, label: t('profile.visaNumber'), value: visaData.visaNumber },
  { icon: <LocalAtm />, label: t('profile.visaFee'), value: visaData.visaFee },

  ];

  const additionalFields = [
    { icon: <Gavel />, label: t('profile.issuingAuthority'), value: visaData.issuingAuthority },
  { icon: <AssignmentInd />, label: t('profile.conditions'), value: visaData.conditions },
  { icon: <AssignmentInd />, label: t('profile.entries'), value: visaData.entries },
  { icon: <DateRange />, label: t('profile.dateOfApplication'), value: visaData.dateOfApplication },
  ];
  
  const gridWithBackgroundStyle = {
    // background: `url('visa-king.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingBlock: `0.8rem`,
    // paddingLeft: `0.9rem`,
  };

  const borderImage = {
    border: `50px solid transparent`,
    borderImage: `url('https://atelierbram.github.io/frame-patterns/assets/svg/meander_152x152_border-image.svg') 50 round round`,
    backgroundColor: `#5d5db1`,
  }
  
  const seamlessBorderStyle = {
    backgroundImage: `url('pattern-9.png')`,
    backgroundSize: `130px`,
    backgroundPosition: `center`,
    opacity: `0.8`,
  }

  const visaDataTitleStyle = {
    lineHeight: 1.66,
  }
  // 旅遊 #055259 #597e64, 商務 #004268, #b9a668
  return (
    <div className="bg-cover flex flex-col items-center justify-center py-2">
      <Paper elevation={3} className="max-w-2xl text-white">
        <Grid container spacing={0} className="rounded-2xl" sx={{backgroundColor: `#055259`}}>
          {/* <Grid item xs={12} sm={12} className="h-6 text-center" style={seamlessBorderStyle}>
          </Grid> */}
          <Grid item xs={12} sm={4} className="text-center rounded-2xl" sx={{backgroundColor: `#597e64`, padding: `1rem !important`}}>
          <p className="text-center text-3xl xs:text-2xl font-bold">𝔽𝕌ℝℍ𝔸𝕍𝔼ℕ 𝕍𝕀𝕊𝔸</p>
            <img src="visa-icon.jpeg" alt="簽證圖示" className="w-1/2 xs:w-full mx-auto my-2 rounded-lg" />
            <Typography variant="h6" className="xs:pt-8">{visaData.visaType}</Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={gridWithBackgroundStyle} className="p-2 xxs:pl-8 xs:pl-4">
            <Grid container spacing={1} className="visa-bg">
              {visaFields.map((field, index) => (
                <Grid item xs={6} sm={6} key={index} className="flex items-center">
                  <div className='flex flex-col items-center w-16 block mr-2 text-center'>
                  {field.icon}
                  <Typography variant="overline" display="block" style={visaDataTitleStyle}>{field.label}</Typography>
                  </div>
                  <Typography variant="body1" className="ml-2">{field.value}</Typography>
                </Grid>
              ))}
              <Grid item xs={12} sm={12}>
                <Divider className="my-4 border-blue-500" />
              </Grid>
              {additionalFields.map((field, index) => (
                <Grid item xs={6} sm={6} key={index} className="flex items-center">
                  <div className='flex flex-col items-center w-16 block mr-2 text-center'>
                  {field.icon}
                  <Typography variant="overline" style={visaDataTitleStyle}>{field.label}</Typography>
                  </div>
                  <Typography variant="body1" className="ml-2">{field.value}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} className="h-6 text-center" style={seamlessBorderStyle}></Grid> */}
        </Grid>
      </Paper>
      <div className="flex items-center mt-2 space-x-2">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Share />}
          onClick={shareVisaInfo}
        >
          分享簽證資訊
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Edit />}
        >
          編輯
        </Button>
        <Button
          variant="contained"
          color='warning'
          startIcon={<Star />}
        >
          收藏
        </Button>
      </div>
    </div>
  );
}
  
export default VisaInfo;