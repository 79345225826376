// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

// 設置i18next的初始配置
i18n.use(backend).use(initReactI18next).init({
    // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
   //  debug: true, // 啟用詳細日誌
   //  lng: navigator.language, // 使用瀏覽器的默認語言
    fallbackLng: "en",
    interpolation: {
       // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
       escapeValue: false,
    },
 });

export default i18n;
